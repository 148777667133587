@import "./../../styles/variable.scss";

.menuNotification {
  a {
    text-decoration: none;
  }
  :global {
    .MuiTooltip-popper {
      width: 745px;
      height: 755px;
      @media screen and (max-height: 800px) {
        height: 400px;
      }
      .MuiTooltip-tooltip {
        margin-top: 14px !important;
        padding: 0;
        background: #f9fbfc;
        border: 1px solid #ffffff;
        box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
        max-width: unset;
        width: 745px;
        height: 755px;
        @media screen and (max-height: 800px) {
          height: 520px;
        }
        .MuiTooltip-arrow:before {
          background: #f9fbfc;
        }
      }
    }
  }
}
.layoutTooltip {
  margin-right: 7px;
  p {
    margin: 0;
  }
  .title {
    padding: 20px;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #223354;
  }

  .notificationLayout {
    display: flex;
    .contentNotication {
      width: 65%;
      padding: 15px;
      overflow: auto;
      max-height: 676px;
      @media screen and (max-height: 800px) {
        max-height: 430px;
      }
      .titleContent {
        color: #223354;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 14px;
      }
      .old {
        margin-top: 14px;
      }
      .itemContent {
        cursor: pointer;
        display: flex;
        padding: 10px;
        border-bottom: 1px dashed #e0e0e0;
        &:hover {
          background-color: #f3f3f4;
          border-radius: 5px;
        }
      }
      .unRead {
        height: 10px;
        width: 10px;
        background: #fea628;
        border-radius: 50%;
        margin-top: 20px;
        margin-right: 6px;
      }
      .read {
        height: 10px;
        width: 10px;
        margin-top: 20px;
        margin-right: 6px;
      }
      .infor {
        display: flex;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
        .inforDetail {
          margin-left: 5px;
          p:first-child {
            color: #223354;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
          }
          p:nth-last-child(2) {
            color: #464646;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 330px;
            margin-top: 5px;
          }
          p:last-child {
            color: #909090;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
    .contentNotication::-webkit-scrollbar {
      width: 6px;
    }
    .contentNotication.thin::-webkit-scrollbar {
      width: 2px;
    }
    .contentNotication::-webkit-scrollbar-track {
      background: $bg-scrollbar-track-notification;
    }
    .contentNotication::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $bg-scrollbar-notification;
    }
    .tab {
      background: #f3f3f4;
      width: 35%;
      height: 676px;
      @media screen and (max-height: 800px) {
        height: 430px;
      }
      border-bottom-left-radius: 4px;
      .titleTab {
        color: #223354;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        padding: 13px 0 20px 20px;
      }
      .items {
        padding: 10px 20px;
        .item {
          display: flex;
          cursor: pointer;
          padding: 10px 0;

          .itemContent {
            p {
              margin-left: 10px;
            }
            p:first-child {
              margin-top: 2px;
              color: #223354;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
            }
            p:last-child {
              color: #8d8d8d;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
            }
          }
          img {
            width: 50px;
            height: 50px;
            padding-left: 10px;
          }
          .total {
            background: #d83c2c;
            margin-left: auto;
            margin-right: 10px;
            height: 22px;
            width: 22px;
            text-align: center;
            padding-top: 3px;
            border-radius: 50%;
            font-size: 10px;
            margin-top: 11px;
            color: #ffffff;
          }
          &:hover {
            background: #e8f0f9;
            border-radius: 10px;
          }
        }
        .active {
          background: #e8f0f9;
          border-radius: 10px;
        }
      }
    }
  }
  .head {
    display: flex;
    justify-content: space-between;
    p:last-child {
      color: #909090;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding: 25px 22px 0 0;
      cursor: pointer;
    }
  }
}
.error {
  text-align: center;
  margin-top: 50px !important;
}

.numberInfo {
  position: absolute;
  background-color: #e74c3c;
  height: 21px;
  width: 23px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 19px;
  top: -3px;
  right: -5px;
  border: 1px solid black;
  color: white;
}
.iconNoti {
  padding: 0 !important;
  filter: brightness(0.9);
}
.iconNoti:hover {
  filter: brightness(1.2);
}
.seen {
  filter: brightness(1.2);
  color: #2c8ce4 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    color: #fea628 !important;
    font-size: 18px !important;
  }
}
.notiButton{
  min-width: 40px !important;
}
